<template>
<div>
  <template class="pt-3">
    <section class="my-3">
      <h2 class="h2">Sentences</h2>
      <div class="mt-1">
        <app-collapse type="shadow" class="mt-2 border p-1">
          <draggable v-model="componentData.content.sentences" class="list-group list-group-flush" tag="ul"
            handle=".sentence-grab-button"
          >
            <transition-group type="transition" name="list">
              <app-collapse-item v-for="(sentence, sentenceIndex) in componentData.content.sentences" :key="sentence._id || sentence.id"
                class="question-item"
                :title="`Sentence ${sentenceIndex + 1}`" :is-visible="sentenceIndex === componentData.content.sentences.length - 1"
              >
                <template slot="header">
                  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                    <div class="d-flex align-items-center w-100 sentence-grab-button" style="cursor: grab;">
                      <feather-icon icon="TargetIcon" />
                      <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ sentence.content || `Sentence id: ${sentence.id}` }}</span>
                    </div>
                    <div style="position: absolute; right: 30px; ">
                      <b-button variant="flat-danger" class="btn-icon rounded-circle"
                        @click.stop="removeSentence(sentenceIndex)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </div>
                </template>
                <sentence-card :sentence-data="sentence" />
              </app-collapse-item>
            </transition-group>
          </draggable>

          <div v-if="!componentData.content.sentences.length" class="text-center"><span>No sentence, create them!</span></div>
        </app-collapse>
        <create-sentence-form class="text-center mt-1"
          @created="addSentence"
        />
      </div>
    </section>
    <!-- <section class="mb-3">
      <div class="d-flex">
        <b-button class="ml-auto" @click="updateBot">
          <b-spinner v-if="isUpdatingBot" small />
          <span v-else>Update challenge bot</span>
        </b-button>
      </div>
    </section> -->
  </template>
</div>
</template>
<script>
import draggable from 'vuedraggable'
import CreateSentenceForm from './_components/CreateSentenceForm.vue'
import SentenceCard from './_components/SentenceCard.vue'

export default {
  components: {
    draggable,
    CreateSentenceForm,
    SentenceCard,
  },
  props:{
    componentData: {type: Object, required: true}
  },
  data() {
    return {
      category: null,
      isUpdating: false,
      isDeleting: false,
      isUpdatingBot: false,
    }
  },
  methods: {
    async updateBot() {
      this.isUpdatingBot = true
      const response = await service.updateBot({ id: this.documentId })
      if (response.data.type === 'DATA') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully update sample record for lesson!' })
      }
      this.isUpdatingBot = false
    },
    async addSentence(sentence) {
      this.componentData.content.sentences.push(sentence)
    },
    async removeSentence(sentenceIndex) {
      this.componentData.content.sentences.splice(sentenceIndex, 1)
    },
  },
}
</script>

<style scoped>
.question-item {
  transition: ease 0.6s
}
</style>
